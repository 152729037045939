module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Paraskevi Gotsopoulou","short_name":"Paraskevi","description":"I create content and stuff. I also know a thing or two about online advertising.","start_url":"/","background_color":"#026879","theme_color":"#026879","display":"standalone","icon":"assets/icons/icon-48x48.png","icons":[{"src":"assets/icons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-128x128.png","sizes":"128x128","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-152x152.png","sizes":"152x152","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable any"},{"src":"assets/icons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable any"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"65d3a0cef1fde2de53adfe20d7c0ead3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7EYGK257D9"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
